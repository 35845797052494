<script lang="tsx">
import { defineComponentBaseUiBadge } from '@core/app/components/base/ui/BaseUiBadge.vue'
import type { BaseVariants } from '@core/types/components'

type Colors = 'primary' | 'simplo' | 'emerald' | 'green'
type Sizes = 'md'

export default defineComponentBaseUiBadge<Colors, BaseVariants, Sizes>({
    props: {
        size: {
            default: 'md',
        },
        color: {
            default: 'primary',
        },
        variant: {
            default: 'solid',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiBadge" as *;

@include badge--size('md') {
    @include sim-text-extra-small;
    @include sim-letters-widespread;

    padding: 0.25rem 0.75rem;
    border-radius: 0.125rem;
}

@include badge--disabled {
    opacity: 0.5;
}

@include badge--color('primary') {
    @include badge--variant('solid', '&') {
        background-color: $sim-c-primary-800;
        color: $sim-c-white;
    }

    @include badge--variant('outlined', '&') {
        color: $sim-c-primary-800;

        @include set-outline-color($sim-c-primary-800);
    }
}

@include badge--color('simplo') {
    @include badge--variant('solid', '&') {
        background-color: $sim-c-simplo-darker;
        color: $sim-c-white;
    }

    @include badge--variant('outlined', '&') {
        color: $sim-c-simplo-darker;

        @include set-outline-color($sim-c-simplo-darker);
    }
}

@include badge--color('emerald') {
    @include badge--variant('solid', '&') {
        background-color: $sim-c-white;
        color: $sim-c-success-500;
    }

    @include badge--variant('outlined', '&') {
        color: $sim-c-success-500;

        @include set-outline-color($sim-c-success-500);
    }
}

@include badge--color('green') {
    @include badge--variant('solid', '&') {
        background-color: $sim-c-success-200;
        color: $sim-c-success-700;
    }

    @include badge--variant('outlined', '&') {
        color: $sim-c-success-700;

        @include set-outline-color($sim-c-success-700);
    }
}

</style>
